import React from "react";
import Helmet from "react-helmet";
import { useStaticQuery, graphql } from "gatsby";
import image from "../../static/offener-prozess.jpg";

function Seo({
  description,
  lang,
  bodyClass,
  meta,
  title,
  featuredImg,
  schemaOrg,
  url,
}) {
  const data = useStaticQuery(graphql`
    query seoQuery {
      site {
        siteMetadata {
          title
          description
          author
          siteUrl
        }
      }
    }
  `);

  const metaDescription = description || data.site.siteMetadata.description;
  const mainImg = featuredImg ?? image;
  const dir = lang === "ar" ? "rtl" : "ltr";

  const indexSchema = {
    "@context": "https://schema.org",
    "@type": "CreativeWork",
    name: data.site.siteMetadata.title,
    url: data.site.siteMetadata.siteUrl,
    author: data.site.siteMetadata.author,
    description: data.site.siteMetadata.description,
    image: mainImg,
    "@id": data.site.siteMetadata.siteUrl,
  };

  return (
    <Helmet
      htmlAttributes={{
        lang,
        dir,
      }}
      bodyAttributes={{
        class: { bodyClass },
      }}
      title={`${title} | ${data.site.siteMetadata.title}`}
      titleTemplate={`%s`}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:title`,
          content: `${title} | ${data.site.siteMetadata.title}`,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:image`,
          content: mainImg,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          property: `og:url`,
          content: url ?? data.site.siteMetadata.siteUrl,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:creator`,
          content: data.site.siteMetadata.author,
        },
        {
          name: `twitter:title`,
          content: `${title} | ${data.site.siteMetadata.title}`,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
        {
          property: `twitter:image`,
          content: mainImg,
        },
      ].concat(meta)}
    >
      {schemaOrg && (
        <script type="application/ld+json">
          {JSON.stringify(indexSchema)}
        </script>
      )}
      <body className={bodyClass} />
    </Helmet>
  );
}

Seo.defaultProps = {
  lang: `de`,
  meta: [],
  description: ``,
};

export default Seo;
